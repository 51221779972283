import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import auth from './auth.js'
import storeFuncts from './storeFuncts.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    properties: [],
    propertyTree: [],
    propertiesSorted: false,
    jobPerfData: {},
    propertyJobTable: {},
    accountings: {},
    cancel: false,
    storePerfJobs: [],
    jobScript: {},
    modules: {}
  },

  getters: {
    hasAccounting (state) {
      return Object.keys(state.accountings).length > 0
    },

    hasJobPerfData (state) {
      return Object.keys(state.jobPerfData).length > 0
    },

    jobs (state) {
      if (window.localStorage.getItem('jobIds')) {
        return JSON.parse(window.localStorage.getItem('jobIds'))
      } else {
        return []
      }
    },

    perfJobs (state) {
      if (window.localStorage.getItem('perfjobs')) {
        return JSON.parse(window.localStorage.getItem('perfjobs'))
      } else {
        return []
      }
    },

    getJobPerfData (state) {
      return function (jobId) {
        return state.jobPerfData[jobId]
      }
    },

    getAllJobPerfData (state) {
      return state.jobPerfData
    },

    accountings (state) {
      return Object.values(state.accountings)
    },

    getJobAccounting (state) {
      return function (jobId) {
        return state.accountings[jobId]
      }
    },

    averageSeverities (state, getters) {
      // calculate average severities
      const result = []
      for (const jid of Object.keys(state.jobPerfData)) {
        const avgrs = getters.averageSeverity(jid)
        if (avgrs) result.push(avgrs)
      }
      return result
    },

    averageSeverity (state) {
      // calculate average severity of one job
      return function (jid) {
        const tsRecord = state.jobPerfData[jid]
        if (tsRecord && tsRecord !== '') {
          const sum = {}
          const averages = {}
          const count = {}
          for (let i = 0; i < tsRecord.length; ++i) {
            const propId = tsRecord[i].property_type_id
            const severity = tsRecord[i].severity_average
            const numObs = tsRecord[i].num_of_observations
            if (sum[propId]) {
              sum[propId] += severity * numObs
              count[propId] += numObs
            } else {
              sum[propId] = severity * numObs
              count[propId] = numObs
            }
          }
          averages.jobId = jid
          Object.keys(sum).forEach(function (key, index) {
            const pid = parseInt(key)
            const propObj = state.properties.find(item => item.property_type_id ===
              pid)
            if (propObj === undefined) {
              // console.log('property name not found for ' + key)
            } else {
              averages[propObj.name] = sum[key] / count[key]
            }
          })
          return averages
        }
        return undefined
      }
    },

    getActivePropInJob (state, getters) {
      return function (jid, averageSeverity) {
        const jobprops = []
        for (const key of Object.keys(averageSeverity)) {
          const propObj = state.properties.find((item) => { return item.name === key })
          if (propObj) jobprops.push(propObj)
        }
        storeFuncts.sortProperties(jobprops, getters.propertyTree)
        return jobprops
      }
    },

    properties (state) {
      return state.properties
    },

    propertyTree (state) {
      return state.propertyTree
    },

    arePropertiesSorted (state) {
      return state.propertiesSorted
    },

    hasProperties (state) {
      return state.properties.length > 0
    },

    hasPropertyTree (state) {
      return state.propertyTree.length > 0
    },

    hasPropertyJobTable (state) {
      return function (jobId) {
        if (state.propertyJobTable[jobId]) {
          return state.propertyJobTable[jobId].length > 0
        }
        return false
      }
    },

    getPropertyJobTable (state) {
      return function (jobId) {
        return state.propertyJobTable[jobId]
      }
    },

    getJobScript (state) {
      return function (jobId) {
        return state.jobScript[jobId]
      }
    },

    allJobsInAccounting (state, getters) {
      const jobIds = JSON.parse(window.localStorage.getItem('jobIds'))
      if (getters.hasAccounting &&
        jobIds.length === Object.keys(state.accountings).length) {
        return true
      }
      return false
    },

    arePerfJobsInStore (state) {
      return state.storePerfJobs.length > 0
    },

    getStorePerfJobs (state) {
      return state.storePerfJobs
    },

    getSearchURL (state, getters) {
      const url = window.localStorage.getItem('searchURL')
      if (url) return url
      return ''
    },

    isCanceled (state) {
      return state.cancel
    }
  },

  mutations: {
    setProperties (state, { properties }) {
      state.properties = properties
    },

    setPropertyTree (state, { propertyTree }) {
      state.propertyTree = propertyTree
    },

    setJob (state, { jobId, job }) {
      state.jobPerfData[jobId] = job
    },

    setJobScript (state, { jobId, script }) {
      state.jobScript[jobId] = script
    },

    setPropertyJobTable (state, { jobId, jobPropertyTable }) {
      state.propertyJobTable[jobId] = jobPropertyTable
    },

    setAccounting (state, { jobId, accounting }) {
      state.accountings[jobId] = accounting
    },

    setPropertiesSorted (state) {
      state.propertiesSorted = true
    },

    storeJobId (state, { jobId }) {
      // check if there are any job ids stored in localStorage
      if (window.localStorage.getItem('jobIds')) {
        // there are job ids stored, get them
        const jobIds = JSON.parse(window.localStorage.getItem('jobIds'))
        // check if the id is already stored
        if (jobIds.indexOf(jobId) === -1) {
          // the job id is not stored in localStorage yet, add it
          jobIds.push(jobId)
          // updatet localStorage
          window.localStorage.setItem('jobIds', JSON.stringify(jobIds))
        }
      } else {
        // there are no job ids, create a new job id array in localStorage
        window.localStorage.setItem('jobIds', JSON.stringify([jobId]))
      }
    },

    storeJobWithPerf (state, { jobId }) {
      const jobFound = state.storePerfJobs.find(jid => { return jid === jobId })
      if (!jobFound) {
        state.storePerfJobs.push(jobId)
      }
      // check if there are any job ids stored in localStorage
      if (window.localStorage.getItem('perfjobs')) {
        // there are job ids stored, get them
        const jobIds = JSON.parse(window.localStorage.getItem('perfjobs'))
        // check if the id is already stored
        if (jobIds.indexOf(jobId) === -1) {
          // the job id is not stored in localStorage yet, add it
          jobIds.push(jobId)
          // updatet localStorage
          window.localStorage.setItem('perfjobs', JSON.stringify(jobIds))
        }
      } else {
        // there are no job ids, create a new job id array in localStorage
        window.localStorage.setItem('perfjobs', JSON.stringify([jobId]))
      }
    },

    setSearchURL (state, url) {
      window.localStorage.setItem('searchURL', url)
    },

    emptyLocalStorage (state) {
      window.localStorage.setItem('jobIds', JSON.stringify([]))
      window.localStorage.setItem('perfjobs', JSON.stringify([]))
      state.storePerfJobs = []
    },

    emptyPerfJobs (state) {
      window.localStorage.setItem('perfjobs', JSON.stringify([]))
      state.storePerfJobs = []
    },

    emptyAccAndPerfData (state) {
      state.jobPerfData = {}
      state.propertyJobTable = {}
      state.accountings = {}
    },

    copyPerfJobsToJobIds (state) {
      const perfjobs = window.localStorage.getItem('perfjobs')
      window.localStorage.setItem('jobIds', perfjobs)
    },

    cancel (state) {
      state.cancel = true
    },

    resetCancel (state) {
      state.cancel = false
    }
  },

  actions: {

    calculatePropertyJobTable ({ commit, getters }, { jobId }) {
      return new Promise((resolve, reject) => {
        let jobPropertyTable = []
        const jobObj = getters.getJobPerfData(jobId)
        if (jobObj && jobObj !== '') {
          const properties = getters.properties
          jobPropertyTable = storeFuncts.jobDataToTable(jobObj, properties)
          commit('setPropertyJobTable', { jobId, jobPropertyTable })
          resolve(jobPropertyTable)
        } else {
          reject(new Error(`Sorry, data is not coherent, we don't have performance data for jobId=${jobId}`))
        }
      })
    },

    fetchProperties ({ commit, getters }) {
      return window.APIClient.refresh().then(resp => {
        return new Promise((resolve, reject) => {
          axios.get(`${process.env.VUE_APP_API_BASE_URL}/property_types/`)
            .then(response => {
              const properties = response.data
              properties.forEach(prop => {
                // renaming for convenience...
                prop.propertyvalMax = prop.propertyval_max
              })
              if (getters.hasPropertyTree && !getters.arePropertiesSorted) {
                storeFuncts.sortProperties(properties, getters.propertyTree)
                commit('setPropertiesSorted')
              }

              commit('setProperties', { properties })
              resolve(properties)
            })
        })
      })
    },

    fetchPropertyTree ({ commit, getters }) {
      return window.APIClient.refresh().then(resp => {
        return new Promise((resolve, reject) => {
          axios.get(`${process.env.VUE_APP_API_BASE_URL}/parent_child/`)
            .then(response => {
              const propertyTree = response.data
              if (getters.hasProperties && !getters.arePropertiesSorted) {
                storeFuncts.sortProperties(getters.properties, propertyTree)
                commit('setPropertiesSorted')
              }
              commit('setPropertyTree', { propertyTree })
              resolve(response.data)
            })
        })
      })
    },

    fetchJobs ({ commit, getters }) {
      commit('resetCancel')
      const jobIds = getters.perfJobs
      return Promise.all(jobIds.map(jobId => {
        return new Promise((resolve, reject) => {
          if (!getters.isCancel) {
            const perfdata = getters.getJobPerfData(jobId)
            if (perfdata) {
              resolve(perfdata)
            } else {
              axios.get(`${process.env.VUE_APP_API_BASE_URL}/performance_data/${jobId}/`)
                .then(response => {
                  const job = response.data
                  commit('setJob', { jobId, job })
                  resolve(job)
                })
            }
          }
        })
      }))
    },

    fetchStoreJobs ({ commit, getters }) {
      commit('resetCancel')
      return Promise.all(getters.getStorePerfJobs.map(jobId => {
        return new Promise((resolve, reject) => {
          if (!getters.isCancel) {
            const perfdata = getters.getJobPerfData(jobId)
            if (perfdata) {
              resolve(perfdata)
            } else {
              axios.get(`${process.env.VUE_APP_API_BASE_URL}/performance_data/${jobId}/`)
                .then(response => {
                  const job = response.data
                  commit('setJob', { jobId, job })
                  resolve(job)
                })
            }
          }
        })
      }))
    },

    fetchAccounting ({ commit }, { jobIds }) {
      const jobGroups = []
      const numInGroup = 10
      for (let i = 0; i < jobIds.length; i += numInGroup) {
        jobGroups.push(jobIds.slice(i, i + numInGroup).join(','))
      }
      if (jobIds.length === 0 || jobGroups.length === 0) {
        return new Promise((resolve, reject) => {
          reject(new Error(`Empty query, jobIds= ${jobIds}`))
        })
      }
      const config = { headers: window.APIClient.getAuthHeader() }
      return Promise.all(jobGroups.map(jobIds => {
        return new Promise((resolve, reject) => {
          axios.get(`${process.env.VUE_APP_API_BASE_URL}/accounting/?job_id_string=${jobIds}`, config)
            .then(response => {
              response.data.forEach(item => {
                const jobId = item.job_id_string
                if (item.perfdata_available === 1) {
                  // if (jobId === null || jobId === undefined) console.log('fetchAccounting has null jobid')
                  commit('storeJobWithPerf', { jobId })
                }
                commit('storeJobId', { jobId })
                commit('setAccounting', { jobId, item })
              })
              resolve(response.data)
            })
        })
      }))
    },

    fetchFilteredAccounting ({ commit }, formdata) {
      const urlQuery = storeFuncts.convertQueryToURL(formdata)
      commit('setSearchURL', urlQuery)
      return window.APIClient.refresh().then(resp => {
        return new Promise((resolve, reject) => {
          const config = { headers: window.APIClient.getAuthHeader() }
          axios.get(urlQuery, config)
            .then(response => {
              const jobidsPerf = []
              const accountings = response.data.results
              for (let i = 0; i < accountings.length; ++i) {
                let jobId = accountings[i].job_id_string
                if (jobId === null) jobId = accountings[i].job_id_string
                const accounting = accountings[i]
                if (!formdata.accOnly && accounting.perfdata_available === 1) {
                  // if (jobId === null || jobId === undefined) console.log('fetchFilteredAccounting has null/undefined jobid')
                  commit('storeJobWithPerf', { jobId })
                  jobidsPerf.push(jobId)
                }
                commit('storeJobId', { jobId })
                commit('setAccounting', { jobId, accounting })
              }
              response.data.jobidsPerf = jobidsPerf
              resolve(response.data)
            })
        })
      })
    },

    countJobsWithPerfdata ({ commit }, formdata) {
      const formdataClone = { ...formdata }
      // change limit to 1
      formdataClone.limit = 1
      formdataClone.perfdataAvailable = 1
      const urlQuery = storeFuncts.convertQueryToURL(formdataClone)
      return window.APIClient.refresh().then(resp => {
        return new Promise((resolve, reject) => {
          const config = { headers: window.APIClient.getAuthHeader() }
          axios.get(urlQuery, config)
            .then(response => {
              resolve(response.data.count)
            })
        })
      })
    },

    countJobs ({ commit }, formdata) {
      const formdataClone = { ...formdata }
      // change limit to 1
      formdataClone.limit = 1
      const urlQuery = storeFuncts.convertQueryToURL(formdataClone)
      return window.APIClient.refresh().then(resp => {
        return new Promise((resolve, reject) => {
          const config = { headers: window.APIClient.getAuthHeader() }
          axios.get(urlQuery, config)
            .then(response => {
              resolve(response.data.count)
            })
        })
      })
    },

    fetchAccountingFromURL ({ commit, getters }) {
      const url = getters.getSearchURL
      const jobIds = getters.jobs
      return window.APIClient.refresh().then(resp => {
        return new Promise((resolve, reject) => {
          const config = { headers: window.APIClient.getAuthHeader() }
          axios.get(url, config)
            .then(response => {
              const accountings = response.data.results
              for (let i = 0; i < accountings.length; ++i) {
                const jobId = accountings[i].job_id_string
                const found = jobIds.find(jid => { return jobId === jid })
                if (found !== undefined) {
                  const accounting = accountings[i]
                  // why not commit('storeJobId', { jobId }) ??
                  commit('setAccounting', { jobId, accounting })
                }
              }
              resolve(response.data.results)
            })
        })
      })
    },

    fetchAccountingFromJob ({ commit }, { jobId }) {
      return window.APIClient.refresh().then(resp => {
        return new Promise((resolve, reject) => {
          const config = { headers: window.APIClient.getAuthHeader() }
          axios.get(`${process.env.VUE_APP_API_BASE_URL}/accounting/${jobId}/`, config)
            .then(response => {
              // console.error('endpoint should return one object?')
              const accounting = response.data
              commit('storeJobId', { jobId })
              commit('setAccounting', { jobId, accounting })
              resolve(response.data)
            })
        })
      })
    },

    fetchPerformanceDataFromJob ({ commit, getters }, { jobId }) {
      // if (jobId === null || jobId === undefined) console.log('fetchPerformanceDataFromJob has null/undefined jobid')
      commit('resetCancel')
      return window.APIClient.refresh().then(resp => {
        return new Promise((resolve, reject) => {
          if (!getters.isCancel) {
            axios.get(`${process.env.VUE_APP_API_BASE_URL}/performance_data/${jobId}/`)
              .then(response => {
                const job = response.data
                commit('storeJobWithPerf', { jobId })
                commit('setJob', { jobId, job })
                resolve(job)
              })
          }
        })
      })
    },

    fetchJobScript ({ commit }, { jobId }) {
      return window.APIClient.refresh().then(resp => {
        const config = { headers: window.APIClient.getAuthHeader() }
        return axios.get(`${process.env.VUE_APP_API_BASE_URL}/jobscript/${jobId}/`, config)
          .then(response => {
            const script = response.data.job_script
            commit('setJobScript', { jobId, script })
          })
      })
    }
  },

  modules: {
    auth
    // accounting
    // performance
  }
})
