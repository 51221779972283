<template>
  <div class="m-4">
    <!-- Table Controls -->
    <b-row v-if="hasItems">
      <b-col>
        <h3>Performance Overview Table <b-button size="sm" @click="openAvgHelp" class="m-2"><b-icon icon="question-circle" size="sm"/></b-button></h3>
      </b-col>
    </b-row>

    <!-- Average Table -->
    <b-table
      ref="averageTable"
      class="average-table text-nowrap text-center"
      striped
      hover
      bordered
      outlined
      responsive
      sticky-header="50vh"
      small
      selectable
      select-mode="single"
      selected-variant="primary"
      @row-selected="onRowSelected"
      :items="table.items"
      :fields="table.fields" >
      <template #cell(action)="data">
        <b-button
          class="m-0"
          style="width: 60px; z-index: 5;"
          size="sm" @click="goToGraph(data.item)"><b-icon icon="hand-index"/></b-button>
      </template>
      <template #cell(jobId)="data">
        {{data.value}}
      </template>

      <template #cell()="data">
        <table-cell :severity="getNumber(data.value)"></table-cell>
      </template>
    </b-table>
    <AvgTableHelp ref="avgTableHelp" />
  </div>
</template>

<script>
import TableCell from '@/components/TableCell.vue'
import errorcommon from '@/common/error.js'

export default {
  name: 'average-table',

  components: {
    TableCell
  },

  data () {
    return {
      table: {
        items: [],
        fields: []
      },
      rowCounter: 0
    }
  },

  computed: {
    hasItems () {
      return this.table.items.length > 0
    }
  },

  async mounted () {
    const self = this
    try {
      if (!self.$store.getters.hasProperties) {
        this.$emit('fetched', 'Perf')
        await self.$store.dispatch('fetchProperties').catch(error => { errorcommon.handleError(error, self.$router) })
        if (!self.$store.getters.hasPropertyTree) { // needed to sort properties
          await self.$store.dispatch('fetchPropertyTree').catch(error => { errorcommon.handleError(error, self.$router) })
        }
        self.$emit('fetched', 'Done')
      }

      self.$emit('fetched', 'Perf')
      self.$store.commit('resetCancel')

      let jobsPromise
      if (!self.$store.getters.arePerfJobsInStore) {
        jobsPromise = self.$store.dispatch('fetchJobs')
      } else {
        jobsPromise = self.$store.dispatch('fetchStoreJobs')
      }
      jobsPromise.then(function (response) {
        const items = self.$store.getters.averageSeverities
        self.initTable(items)
      }).then(resp => { self.$emit('fetched', 'Done') }).catch(error => { errorcommon.handleError(error, self.$router) })
    } catch (error) {
      errorcommon.handleError(error, self.$router)
    }
  },

  methods: {
    onRowSelected (item) {
      if (item[0] && this.rowCounter === 0) {
        this.$emit('rowSelected', item[0].jobId)
      }
      this.rowCounter = 0
    },

    selectRow (msg) {
      const found = this.table.items.findIndex(row => { return row.jobId === msg })
      if (found !== -1) {
        this.rowCounter = 1
        this.$refs.averageTable.selectRow(found)
      }
    },

    goToGraph (row) {
      this.$emit('timeline', 'Create')
      this.$router.push({ name: 'Timeline', params: { jobId: row.jobId } })
    },

    initTable (items) {
      this.table.items = items // this.$store.getters.averageSeverities
      const properties = this.$store.getters.properties
      this.table.fields = properties.map(property => {
        return {
          key: property.name,
          sortable: true
        }
      })
      this.table.fields.unshift({
        key: 'jobId',
        label: 'Job Id',
        stickyColumn: true,
        sortable: true
      })
      this.table.fields.unshift({ key: 'action', label: 'Timeline' })
    },

    getNumber (val) {
      if (val === '') {
        return -1
      }
      return val
    },

    openAvgHelp (evt) {
      evt.preventDefault()
      this.$refs.avgTableHelp.show()
    }
  }
}
</script>

<style >
.average-table th {
  min-width: 110px;
}

.average-table tbody td {
  padding: 0;
  hight: 100%;
  width: 100%;
}

tr:focus {
  outline: 2;
}
</style>
