<template>
    <div class="m-4">
      <b-row>
        <b-col v-if="table.items.length === 0">
          {{ initMessage }}
        </b-col>
      </b-row>
      <!-- Table Controls -->
      <b-row v-if="hasItems">
        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="table.currentPage"
            :total-rows="table.totalRows"
            :per-page="table.perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
        <b-col>
          Performance of selected jobs: <b-button size="sm" @click="fetchData()">Fetch</b-button>
        </b-col>
      </b-row>

      <!-- Accounting Information Table -->
      <b-table
        class="accounting-table text-nowrap"
        striped
        hover
        bordered
        outlined
        responsive
        selectable
        selected-variant="primary"
        sticky-header="90vh"
        small
        :items="table.items"
        :fields="table.fields"
        :per-page="table.perPage"
        :currentPage="table.currentPage"
        @row-selected="onRowSelected"
      >
        <template #cell(action)="data">
          <div>
            <b-button
              class="m-0"
              style="width: 60px; z-index: 5;"
              size="sm"
              @click="goToTimeline(data.item)"
              :disabled="data.item.perfdata_available === 0"
            ><b-icon icon="hand-index" v-if="data.item.perfdata_available === 1" class="mr-1"/><b-icon icon="x-circle" v-else/></b-button>
          </div>
        </template>
        <template #cell(job_id_string)="data">
          {{ shortJobName(data.item.job_id_string) }}
        </template>
      </b-table>
      <average-table v-if="severityTable"/>
    </div>
</template>

<script>
import errorcommon from '@/common/error.js'
import acctable from '@/common/acctable.js'

export default {
  name: 'only-accounting-table',

  computed: {
    hasItems () {
      return this.table.items.length > 0
    }
  },

  data () {
    return {
      table: {
        items: [], // initialized on component mount
        fields: [], // initialized through items
        perPage: 12, // number of rows to display by default
        currentPage: 1, // start at first page
        totalRows: -1 // initialized on component mount
      },
      stickyHeader: '90vh',
      selected: [],
      severityTable: false,
      initMessage: 'Fetching data'
    }
  },

  mounted () {
    const self = this
    self.initMessage = 'Fetching data'
    // check if vuex store has accounting details
    let allJobsInAcc

    try {
      allJobsInAcc = this.$store.getters.allJobsInAccounting
    } catch (error) {
      allJobsInAcc = false
    }

    this.table.perPage = Math.floor(Math.round(window.innerHeight * 0.6) / 41)

    if (allJobsInAcc) {
      const items = this.$store.getters.accountings
      if (items[0]) { // not there yet...
        this.initTable(items)
        return
      }
    }
    // vuex store has no accounting details, check if there are any job ids stored in
    // localStorage
    const jobIds = this.$store.getters.jobs
    if (jobIds.length > 0) {
      // refetch accounting information for job ids from localStorage
      let fetch = 'fetchAccounting'
      if (jobIds.length > 30) fetch = 'fetchAccountingFromURL'
      // console.log(fetch)
      this.$store.dispatch(fetch, { jobIds })
        .then(function (items) {
          if (items.length > 0 && Array.isArray(items[0])) {
            self.initTable(items.flat())
          } else if (items.length > 0) {
            self.initTable(items)
          }
        }).catch(error => { errorcommon.handleError(error, self.$router) })
    }
    if (self.table.items.length === 0) self.initMessage = 'No jobs found in your last query!'
  },

  methods: {
    initTable (items) {
      this.table.items = acctable.addCoreHours(items)
      this.table.totalRows = this.table.items.length
      // generate table column header fields from first row
      this.table.fields = acctable.init_acc_table(this.table.items)
    },
    goToTimeline (row) {
      this.$emit('timeline', 'Create')
      this.$router.push({ name: 'Timeline', params: { jobId: row.job_id_string } })
    },
    shortJobName (name) {
      return name.length > 48 ? name.substring(0, 45) + '...' : name
    },
    onRowSelected (items) {
      const self = this
      self.selected = [] // initialize
      items.forEach(record => {
        self.selected.push(record)
      })
    },

    fetchData () {
      const self = this
      if (this.selected.length === 0) {
        alert('No jobs were selected!')
        return
      }
      // for each selected job, check whether it has perf data
      const perfDataJobs = []
      self.selected.forEach(job => {
        if (job.perfdata_available === 1) {
          perfDataJobs.push(job.job_id_string)
        }
      })
      if (perfDataJobs.length === 0) {
        alert('Selected jobs have no performance data!')
        return
      }
      if (perfDataJobs.length > 30) {
        alert('Too many jobs with performance selected!')
        return
      }
      this.$store.commit('emptyPerfJobs')
      const promises = []
      perfDataJobs.forEach(jobId => {
        promises.push(this.$store.dispatch('fetchPerformanceDataFromJob', { jobId }))
      })
      Promise.all(promises).then(r1 => {
        self.severityTable = true
      }).catch(error => { errorcommon.handleError(error, self.$router) })
    }
  }
}
</script>

<style>
.accounting-table tr td:first-child {
  z-index: 4 !important;
}

.accounting-table thead th:first-child {
  z-index: 10 !important;
}
</style>
