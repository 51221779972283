<template>
    <div class="m-4">
      <b-row>
        <b-col v-if="table.items.length === 0">
          No jobs found on your last query!
        </b-col>
      </b-row>

      <!-- Loading next screen... -->
      <div v-if="loadingNextScreen">Loading timetable...</div>

      <!-- Accounting Information Table -->
      <b-table ref="acctable"
        class="accounting-table text-nowrap"
        striped
        hover
        bordered
        outlined
        responsive
        sticky-header="50vh"
        small
        selectable
        select-mode="single"
        selected-variant="primary"
        @row-selected="onRowSelected"
        :items="table.items"
        :fields="table.fields">
        <template #cell(action)="data">
          <div>
            <b-button
              class="m-0"
              style="width: 60px; z-index: 5;"
              size="sm"
              @click="goToTimeline(data.item)"
              :disabled="data.item.perfdata_available === 0"
            ><b-icon icon="hand-index" v-if="data.item.perfdata_available === 1" class="mr-1"/><b-icon icon="x-circle" v-else/></b-button>
          </div>
        </template>
        <template #cell(job_id_string)="data">
          {{ shortJobName(data.item.job_id_string) }}
        </template>
      </b-table>
    </div>
</template>

<script>
import errorcommon from '@/common/error.js'
import acctable from '@/common/acctable.js'

export default {
  name: 'accounting-table',

  computed: {
    hasItems () {
      return this.table.items.length > 0
    }
  },

  data () {
    return {
      table: {
        items: [], // initialized on component mount
        fields: [], // initialized through items
        totalRows: -1 // initialized on component mount
      },
      rowCounter: 0,
      loadingNextScreen: false
    }
  },

  mounted () {
    // check if vuex store has accounting details
    let allJobsInAcc

    try {
      allJobsInAcc = this.$store.getters.allJobsInAccounting
    } catch (error) {
      allJobsInAcc = false
    }
    if (allJobsInAcc) {
      const items = this.$store.getters.accountings
      if (items[0]) { // not there yet...
        this.initTable(items)
        return
      }
    }
    // vuex store has no accounting details, check if there are any job ids stored in
    // localStorage
    const jobIds = this.$store.getters.jobs
    if (jobIds.length > 0) {
      // refetch accounting information for job ids from localStorage
      this.fetchAcc(jobIds)
    }
  },

  methods: {
    async fetchAcc (jobIds) {
      const self = this
      this.$store.dispatch('fetchAccounting', { jobIds })
        .then(function (items) {
          if (items.length > 0 && Array.isArray(items[0])) {
            self.initTable(items.flat())
          } else if (items.length > 0) {
            self.initTable(items)
          }
        }).catch(error => { errorcommon.handleError(error, self.$router) })
    },

    initTable (items) {
      this.table.items = acctable.addCoreHours(items)
      this.table.totalRows = this.table.items.length
      // generate table column header fields from first row
      this.table.fields = acctable.init_acc_table(this.table.items)
    },
    goToTimeline (row) {
      this.loadingNextScreen = true
      this.$emit('timeline', 'Create')
      this.$router.push({ name: 'Timeline', params: { jobId: row.job_id_string } })
    },
    shortJobName (name) {
      return name.length > 48 ? name.substring(0, 45) + '...' : name
    },

    rowHeight (item, type) {
      return 'table-cell'
    },

    onRowSelected (item) {
      if (item[0] && this.rowCounter === 0) {
        this.$emit('rowSelected', item[0].job_id_string)
      }
      this.rowCounter = 0
    },

    selectRow (msg) {
      const found = this.table.items.findIndex(row => { return row.job_id_string === msg })
      if (found !== -1 && this.rowCounter === 0) {
        this.rowCounter = 1
        this.$refs.acctable.selectRow(found)
      }
    }
  }
}
</script>

<style>
.table-cell {
  max-height: 30px !important;
}
</style>
