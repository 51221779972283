<template>
  <div>
    <b-modal ref="searchFilterModal" title="Job Search Filter" size="lg">
      <!-- Inputs for job search details -->
      <b-form inline class="mb-2">
       <!-- Not yet needed
       <b-form-group label="Batchdomain" label-cols-sm="4">
         <b-form-radio-group
           v-model="form.data.batchdomain"
           :options="form.input.batchdomain"
           value-field="item"
           text-field="name"
           disabled-field="notEnabled"
         ></b-form-radio-group>
       </b-form-group>
       -->

       <!-- Job Class -->
       <b-button v-b-toggle.collapse-2 variant="primary" v-b-tooltip.hover title="Click to see/hide choices" >Job Class: {{ form.data.jobClassAll ? 'All' : 'Filtered' }} </b-button>
       <b-collapse id="collapse-2" class="mt-2">
         <b-card>
         <b-form-group>
           <template #label>
             <b-form-checkbox
               v-model="form.data.jobClassAll"
               :indeterminate="form.data.jcindeterminate"
               aria-describedby="form.input.jobClasses"
               aria-controls="form.input.jobClasses"
               @change="toggleJCAll"
             >
         {{ form.data.jobClassAll ? 'Un-select some' : 'Select all or at least one' }}
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="jobclass"
              v-model="form.data.jobClass"
              :options="form.input.jobClasses"
              :aria-describedby="ariaDescribedby"
              name="Job Class"
              class="ml-4"
              aria-label="JobClass"
             :state="jobClassValid"
           ></b-form-checkbox-group>
          </template>
          <b-form-invalid-feedback :state="jobClassValid">Please at least one</b-form-invalid-feedback>
          <b-form-valid-feedback :state="jobClassValid">Thank you</b-form-valid-feedback>
        </b-form-group>
        </b-card>
      </b-collapse>
      </b-form>

      <b-input-group prepend="Project IDs">
        <b-form-input
          v-model="form.data.projectId"
          placeholder="Project Ids comma separated"
        ></b-form-input>
      </b-input-group>

      <b-input-group prepend="User Ids" class="mt-2">
        <b-form-input
          v-model="form.data.userId"
          placeholder="User Ids comma separated"
        ></b-form-input>
      </b-input-group>

      <b-container>
        <b-row>
          <b-col>
        <b-input-group prepend="Minimum nodes" class="mt-2 mb-2 mr-1">
          <b-form-input
            type="number"
            v-model="form.data.nodesMin"
            min="0"
          ></b-form-input>
        </b-input-group>
          </b-col>
          <b-col>
        <b-input-group prepend="Maximum nodes" class="mt-2 mb-2 mr-1">
          <b-form-input
            type="number"
            v-model="form.data.nodesMax"
            min="0"
          ></b-form-input>
        </b-input-group>
          </b-col>
       </b-row>

       <b-row>
         <b-col>
       <b-input-group prepend="Min wallclock" class="mt-2 mb-2">
         <b-form-input
           type="text"
           v-model="form.data.wallclockMin"
           placeholder="HH:mm:ss"
         ></b-form-input>
       </b-input-group>
          </b-col>

          <b-col>
       <b-input-group prepend="Max wallclock" class="mt-2 mb-2">
         <b-form-input
           type="text"
           v-model="form.data.wallclockMax"
           placeholder="HH:mm:ss"
         ></b-form-input>
       </b-input-group>
          </b-col>
       </b-row>
      </b-container>

      <!-- Job State -->
      <b-button v-b-toggle.collapse-1 variant="primary" v-b-tooltip.hover title="Click to see/hide choices">Job State: {{ form.data.stateAll ? 'All' : 'Filtered' }} </b-button>
      <b-collapse id="collapse-1" class="mt-2">
      <b-card>
        <b-form-group>
          <template #label>
            <b-form-checkbox
              v-model="form.data.stateAll"
              :indeterminate="form.data.indeterminate"
              aria-describedby="form.input.states"
              aria-controls="form.input.states"
              @change="toggleAll"
            >
         {{ form.data.stateAll ? 'Un-select some' : 'Select all or at least one' }}
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="state"
              v-model="form.data.state"
              :options="form.input.states"
              :aria-describedby="ariaDescribedby"
              name="State"
              class="ml-4"
              aria-label="State"
             :state="stateValid"
           ></b-form-checkbox-group>
          </template>
          <b-form-invalid-feedback :state="stateValid">Please at least one</b-form-invalid-feedback>
          <b-form-valid-feedback :state="stateValid">Thank you</b-form-valid-feedback>
        </b-form-group>
      </b-card>
    </b-collapse>

    <!-- Max number of jobs -->
      <b-form inline>
        <b-input-group prepend="Max number of jobs" class="mb-2 mr-5 mt-2">
          <b-form-input
            type="number"
            v-model="form.data.limit"
            min="0"
            :state="jobCountValidation"
          ></b-form-input>
        </b-input-group>

        <b-form-checkbox
          v-model="form.data.accOnly"
         >
         Fetch accounting data only
        </b-form-checkbox>

        <div v-if="!jobCountValidation" style="color:darkorange;"><b-icon icon="exclamation-triangle-fill" variant="warning"/> Requesting more than 20 jobs with performance may take long to fetch. First check the "count" button or request accounting data only. You can still try your luck and fetch all performance that you request.</div>
      </b-form>

      <b-form inline>
      <b-form-group label="Start of End Date/Time" class="mr-2 mt-4">
        <b-form-datepicker v-model="form.data.startDate"></b-form-datepicker>
        <b-form-timepicker v-model="form.data.startTime"></b-form-timepicker>
      </b-form-group>

      <b-form-group label="End of End Date/Time" class="mt-4">
        <b-form-datepicker v-model="form.data.endDate"></b-form-datepicker>
        <b-form-timepicker v-model="form.data.endTime"></b-form-timepicker>
      </b-form-group>
      </b-form>

      <!-- Action buttons on the modal footer -->
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" @click="hide">cancel</b-button>
        <b-button ref="countBtn" :disabled="form.data.accOnly" variant="primary" @click="count">count</b-button>
        <b-button variant="info" @click="search">search</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required, minLength, between } from 'vuelidate/lib/validators'
import errorcommon from '@/common/error.js'

export default {
  name: 'search-filter',

  data () {
    return {
      form: {
        data: {
          batchdomain: '1010',
          projectId: '',
          userId: '',
          nodesMin: 0,
          nodesMax: 6336,
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
          wallclockMin: '',
          wallclockMax: '',
          limit: 20,
          jobClass: ['general', 'large', 'fat', 'micro', 'test', 'preempt'],
          jobClassAll: true,
          jcindeterminate: false,
          state: ['TIMEOUT', 'COMPLETED', 'FAILED', 'CANCELLED', 'NODE_FAIL', 'OUT_OF_MEMORY', 'RESIZING', 'DEADLINE', 'REQUEUED', 'PREEMPTED'],
          stateAll: true,
          indeterminate: false,
          accOnly: false,
          countOnly: false
        },
        input: {
          batchdomain: [
            { item: '1010', name: 'Phase 1' },
            { item: 'phase2', name: 'Phase 2', notEnabled: true }
          ],
          jobClasses: ['general', 'large', 'fat', 'micro', 'test', 'preempt'],
          states: ['TIMEOUT', 'COMPLETED', 'FAILED', 'CANCELLED', 'NODE_FAIL', 'OUT_OF_MEMORY', 'RESIZING', 'DEADLINE', 'REQUEUED', 'PREEMPTED']
        }
      }
    }
  },

  // TODO
  validations: {
    form: {
      data: {
        nodesMin: {
          required,
          between: between(0, 6336)
        },
        nodesMax: {
          required,
          between: between(0, 6336)
        },
        state: {
          required,
          minLength: minLength(1)
        },
        jobClass: {
          required,
          minLength: minLength(1)
        },
        limit: {
          required,
          between: between(0, 15000)
        }
      }
    }
  },

  computed: {
    jobCountValidation () {
      return this.form.data.accOnly || this.form.data.limit < 21
    },
    selectedStates () {
      return this.form.data.state
    },
    stateValid () {
      return this.form.data.state.length > 0
    },
    selectedJobClasses () {
      return this.form.data.jobClass
    },
    jobClassValid () {
      return this.form.data.jobClass.length > 0
    }
  },

  methods: {
    show () {
      this.$refs.searchFilterModal.show()
    },

    hide () {
      this.$refs.searchFilterModal.hide()
    },

    async count (evt) {
      const self = this
      evt.preventDefault()
      this.form.data.countOnly = true
      let perfTotal = 0
      let total = 0
      try {
        perfTotal = await this.$store.dispatch('countJobsWithPerfdata', this.form.data).catch(error => { errorcommon.handleError(error, self.$router) })
        if (perfTotal > self.form.data.limit) {
          perfTotal = self.form.data.limit
        }
        total = await this.$store.dispatch('countJobs', this.form.data).catch(error => { errorcommon.handleError(error, self.$router) })
        if (total > self.form.data.limit) {
          total = self.form.data.limit
        }
      } catch (error) {
        errorcommon.handleError(error, self.$router)
      }

      if (total === 0) {
        alert('No jobs found under this search')
      } else {
        alert(`You have selected ${total} job${total > 1 ? 's' : ''} having ` +
        `${perfTotal} job${perfTotal > 1 || perfTotal === 0 ? 's' : ''} ` +
        'with performance data.\n' +
        (perfTotal > 21 ? ('Fetching the performance for ' + perfTotal +
        ' jobs may take long.\nConsider filtering more jobs') : ''))
      }
    },

    async search () {
      const self = this
      try {
        this.hide()
        await this.$store.commit('emptyLocalStorage')
        this.$store.commit('emptyAccAndPerfData')
        await this.$store.dispatch('fetchProperties').catch(error => { errorcommon.handleError(error, self.$router) })
        await this.$store.dispatch('fetchPropertyTree').catch(error => { errorcommon.handleError(error, self.$router) })
        // IMPORTANT: accounting information has to be fetched first.
        // It stores the job ids in localStorage which are then used
        // by fetchJobs to get the performance data for all stored job ids.
        // We ignore the rest of the filter options
        // console.log(this.form.data, this.$v)
        if (!self.validEntries()) return
        self.form.data.countOnly = false
        this.$emit('fetched', 'Acc')
        await this.$store.dispatch('fetchFilteredAccounting', this.form.data).catch(error => { errorcommon.handleError(error, self.$router) })
        // all required data is fetched navigate user to job view
        if (this.form.data.accOnly) {
          if (this.$route.name === 'JobsAO') {
            this.$router.go()
          } else {
            this.$router.push({ name: 'JobsAO' }).catch(_ => { })
          }
        } else {
          if (this.$route.name === 'Job') {
            this.$router.go()
          } else {
            this.$router.push({ name: 'Job' }).catch(_ => { })
          }
        }
      } catch (error) {
        errorcommon.handleError(error, self.$router)
      } finally {
        this.$emit('fetched', 'Done')
      }
    },

    validEntries () {
      if (!this.$v.form.data.nodesMin.between || !this.$v.form.data.nodesMin.required) {
        alert('Please choose a minimum amount of nodes within range')
        return false
      } else if (!this.$v.form.data.nodesMin.between || !this.$v.form.data.nodesMin.required) {
        alert('Please choose a maximum amount of nodes within range')
        return false
      } else if (!this.$v.form.data.state.required || !this.$v.form.data.state.minLength) {
        alert('Please choose at least one job state')
        return false
      } else if (!this.$v.form.data.limit.required || !this.$v.form.data.limit.between) {
        alert('Please give a maximum number of jobs which is equal or less than 15000.')
        return false
      } else if (!this.$v.form.data.jobClass.required || !this.$v.form.data.jobClass.minLength) {
        alert('Please choose at least one job class')
        return false
      }
      return true
    },

    toggleAll (checked) {
      this.form.data.state = checked ? this.form.input.states.slice() : []
    },

    toggleJCAll (checked) {
      this.form.data.jobClass = checked ? this.form.input.jobClasses.slice() : []
    }
  },

  watch: {
    selectedJobClasses (newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.form.data.jcindeterminate = false
        this.form.data.jobClassAll = false
      } else if (newValue.length === this.form.input.jobClasses.length) {
        this.form.data.jcindeterminate = false
        this.form.data.jobClassAll = true
      } else {
        this.form.data.jcindeterminate = true
        this.form.data.jobClassAll = false
      }
    },
    selectedStates (newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.form.data.indeterminate = false
        this.form.data.stateAll = false
      } else if (newValue.length === this.form.input.states.length) {
        this.form.data.indeterminate = false
        this.form.data.stateAll = true
      } else {
        this.form.data.indeterminate = true
        this.form.data.stateAll = false
      }
    }
  }

}
</script>
