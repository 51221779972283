export default {
  init_acc_table (items) {
    // generate table column header fields from first row
    const fields = Object.keys(items[0]).map(function (item, index, array) {
      if (item === 'job_id_string') {
        // and make job_id_string to a sticky column
        return {
          key: item,
          label: 'Job Id',
          sortable: true,
          stickyColumn: true
        }
      } else if (item === 'wallclock') {
        return {
          key: item,
          label: 'Wallclock [s]',
          sortable: true
        }
      } else if (item === 'maxrss') {
        return {
          key: item,
          label: 'Max RSS [KB/Node]',
          sortable: true
        }
      } else if (item === 'frequency') {
        return {
          key: item,
          label: 'Frequency [MHz]',
          sortable: true
        }
      } else if (item === 'avg_power') {
        return {
          key: item,
          label: 'Average Power [W]',
          sortable: true
        }
      } else if (item === 'energy_consumption') {
        return {
          key: item,
          label: 'Energy Consumed [J]',
          sortable: true
        }
      } else if (item === 'user_time') {
        return {
          key: item,
          label: 'User CPU Time [s]',
          sortable: true
        }
      } else if (item === 'system_time') {
        return {
          key: item,
          label: 'System CPU Time [s]',
          sortable: true
        }
      } else if (item === 'reserved_time') {
        return {
          key: item,
          label: 'Reserved Time [s]',
          sortable: true
        }
      } else if (item === 'submission_time' || item === 'job_id_raw' || item === 'perfdata_available') {
        // removing from table
        return
      }
      return {
        key: item,
        sortable: true
      }
    })
    // insert the action button to every row
    fields.unshift({ key: 'action', label: 'Timeline' })
    return fields
  },

  addCoreHours (items) {
    // items.forEach(element => {
    //  const coreh = element.cores * (element.wallclock / 3600) * element.accountable * element.budgeting_factor
    //  element.core_hour = coreh
    //  element.code_hour.toFixed(2)
    // })
    const orderedKeys = Object.keys(items[0])
    // console.log(orderedKeys)
    let wallclockFound = false
    const newitems = []
    items.forEach(element => {
      const coreh = element.cores * (element.wallclock / 3600) * element.accountable * element.budgeting_factor
      // console.log(coreh)
      const newobj = {}
      orderedKeys.forEach(key => {
        if (key !== 'accountable' && key !== 'budgeting_factor') {
          newobj[key] = element[key]
        }
        if (key === 'wallclock') {
          wallclockFound = true
        }
        if (wallclockFound) { // Write related fields
          newobj.accountable = element.accountable
          newobj.budgeting_factor = element.budgeting_factor
          newobj.core_hour = Math.round(coreh)
          wallclockFound = false // Done with writing
        }
      })
      newitems.push(newobj)
    })
    return newitems
  }
}
