<template>
  <b-container fluid>
    <!--b-row class="ovf"-->
    <b-row>
      <b-col>
      <only-accounting-table @timeline="reEmitTL"></only-accounting-table></b-col>
    </b-row>
    <b-row class="justify-content-md-center mt-3">
     <h3>Create a Core-Hour summary</h3>
    </b-row>
    <b-row align-v="center">
      <b-col cols="5">
        <span style="text-align:left; font-weight:bold;">Summary options:</span>
        <b-card no-body style="max-width: 45rem;" class="ml-2">
          <b-tabs card content-class="mt-3">
            <b-tab title="Interval">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="timeSummary.selected"
                  :options="timeSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="time-radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <b-icon icon="exclamation-triangle-fill" variant="warning"/> Note: uses job's end time to summarize
            </b-tab>
            <b-tab title="Project and user">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-2"
                  v-model="groupingSummary.selected"
                  :options="groupingSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="grouping-radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-tab>
            <b-tab title="Accountable">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-3"
                  v-model="accountableSummary.selected"
                  :options="accountableSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="accountable-radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <b-icon icon="exclamation-triangle-fill" variant="warning"/> Jobs which are marked not accountable are not substracted from your core-hour budget. Typically, these are jobs with node failures.
            </b-tab>
            <b-tab title="Job state">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-4"
                  v-model="stateSummary.selected"
                  :options="stateSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="state-radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-tab>
            <b-tab title="Job size">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-5"
                  v-model="nnodesSummary.selected"
                  :options="nnodesSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="size-radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-tab>
            <b-tab title="Partition">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-6"
                  v-model="partitionSummary.selected"
                  :options="partitionSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="partition-radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col>
        <b-overlay :show="calcSummaryBusy" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
          <b-button @click="calculateSummary()" class="mr-3"><b-icon icon="file-earmark-spreadsheet-fill"/> Calculate summary</b-button>
        </b-overlay>
        <span class="h4"><b-icon v-if="summaryData.length !== 0" icon="arrow-down" animation="cylon-vertical"/></span>
      </b-col>
      <b-col>
         <b-button @click="copy()" v-if="summaryData.length !== 0"><b-icon icon="clipboard"/> Copy in CSV format</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
      <b-table id="summary-table"
        v-if="summaryData.length !== 0" :items="summaryData" :fields="fields"
        striped
        hovered
        small
        bordered
        outlined
        responsive
        sticky-header="45vh"
        :tbody-transition-props="transProps"
        no-footer-sorting
        class="mt-3">
        <template #cell(Core_Hours)="data">
          {{ data.item.Core_Hours.toFixed(2) }}
        </template>
        <template v-slot:custom-foot>
          <tr variant="warning">
            <td :colspan="footer.colspan" >Total Sum:</td>
            <td>{{ footer.Core_Hours.toFixed(2) }}</td>
            <td>{{ footer['Number of Jobs'] }} </td>
          </tr>
        </template>
      </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <br>
      <strong>* Core Hours: are weighted with the budgeting factor which is usually equal to 1. Refer to the column "Budgeting Factor" in order to see the actual value.</strong>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import errorcommon from '@/common/error.js'

export default {
  name: 'job-acc-only',

  data () {
    return {
      timeSummary: {
        options: ['All', 'Weekly', 'Monthly'],
        selected: 'All'
      },
      groupingSummary: {
        options: ['All', 'Per Project', 'Per project and user', 'Per user'],
        selected: 'All'
      },
      accountableSummary: {
        options: ['All', 'Only Accountable'],
        selected: 'All'
      },
      stateSummary: {
        options: ['All', 'Per state'],
        selected: 'All'
      },
      nnodesSummary: {
        options: ['All', 'Per job size'],
        selected: 'All'
      },
      partitionSummary: {
        options: ['All', 'Per partition'],
        selected: 'All'
      },
      summaryData: [],
      fields: [],
      footer: {},
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      calcSummaryBusy: false
    }
  },

  methods: {
    async calculateSummary () {
      const self = this
      self.calcSummaryBusy = true
      let allJobsInAcc
      try {
        allJobsInAcc = this.$store.getters.allJobsInAccounting
      } catch (error) {
        allJobsInAcc = false
      }
      let accounting
      if (allJobsInAcc && this.$store.getters.accountings[0]) {
        accounting = this.$store.getters.accountings
      } else {
        const jobIds = this.$store.getters.jobs
        if (jobIds.length > 0) {
          let fetch = 'fetchAccounting'
          if (jobIds.length > 30) fetch = 'fetchAccountingFromURL'
          await this.$store.dispatch(fetch, { jobIds })
            .then(function (items) {
              accounting = items.flat()
            })
            .catch(error => {
              errorcommon.handleError(error, self.$router)
            })
        }
      }
      let jobsizelarge = false
      if (this.nnodesSummary.selected === 'Per job size') {
        jobsizelarge = this.isJobSizeListLarge(accounting)
      }
      const categoryMap = new Map()
      this.summaryData = [] // purge previous content and initialize
      this.fields = []
      this.footer = { Core_Hours: 0, 'Number of Jobs': 0 }
      accounting.forEach(record => {
        if (this.accountableSummary.selected === 'Only Accountable' && record.accountable === 0) {
          return // inside a forEach equivalent to "continue"
        }
        const key = self.getKey(record, jobsizelarge)
        const accum = categoryMap.get(key)
        const hours = (record.wallclock / 3600) * record.cores * record.budgeting_factor
        self.footer.Core_Hours += hours
        self.footer['Number of Jobs'] += 1
        if (accum) {
          accum.hours += hours
          accum.jobs += 1
        } else {
          categoryMap.set(key, { hours: hours, jobs: 1 })
        }
      })
      const catg = this.getCategories()
      this.setFields(catg)
      categoryMap.forEach((value, key, map) => {
        // map is not needed and thus ignored
        const splitCat = key.split(' ')
        const tmpObj = {}
        splitCat.forEach((catVal, index) => {
          tmpObj[catg[index]] = catVal
        })
        tmpObj.Core_Hours = value.hours
        tmpObj['Number of Jobs'] = value.jobs
        this.summaryData.push(tmpObj)
      })
      self.footer.colspan = catg.length
      self.calcSummaryBusy = false
    },

    setFields (categories) {
      categories.forEach(category => {
        this.fields.push({ key: category, sortable: true })
      })
      this.fields.push({ key: 'Core_Hours', sortable: true, label: 'Core Hours*' })
      this.fields.push({ key: 'Number of Jobs', sortable: true })
    },

    isTotal () {
      const all = 'All'
      return this.timeSummary.selected === all && this.groupingSummary.selected === all && this.stateSummary.selected === all &&
        this.partitionSummary.selected === all && this.nnodesSummary.selected === all
    },

    getKey (record, jsizel) {
      if (this.isTotal()) {
        return 'Total'
      }
      let key = ''
      switch (this.groupingSummary.selected) {
        case 'Per Project':
          key = record.project
          break
        case 'Per project and user':
          key = record.project + ' ' + record.user
          break
        case 'Per user':
          key = record.user
          break
      }
      if (this.stateSummary.selected === 'Per state') {
        key === '' ? key = record.state : key += ' ' + record.state
      }
      const getWeek = function (date) {
        const onejan = new Date(date.getFullYear(), 0, 1)
        return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7) - 1
      }
      const date = new Date(record.end_time)
      switch (this.timeSummary.selected) {
        case 'Monthly':
          if (key !== '') {
            key += ' '
          }
          key += date.getFullYear() + '-' + (date.getMonth() + 1)
          break
        case 'Weekly':
          if (key !== '') {
            key += ' '
          }
          key += date.getFullYear() + '-' + (date.getMonth() + 1) + '_Week_' + getWeek(date)
          break
      }
      if (this.nnodesSummary.selected === 'Per job size') {
        if (key !== '') key += ' '
        if (jsizel) {
          let k = 2
          for (; k <= record.nodes; k <<= 1);
          const prevRange = k >> 1
          key += (prevRange === 1 ? '1' : prevRange + 1).toString() + '-' + k.toString()
        } else {
          key += record.nodes.toString()
        }
      }
      if (this.partitionSummary.selected === 'Per partition') {
        key === '' ? key = record.job_class : key += ' ' + record.job_class
      }
      return key
    },

    isJobSizeListLarge (records) {
      const jsizes = new Set()
      const BreakException = {}
      try {
        records.forEach(record => {
          jsizes.add(record.nodes)
          if (jsizes.size > 40) throw BreakException
        })
      } catch (e) {
        if (e === BreakException) return true
      }
      return false
    },

    getCategories () {
      if (this.isTotal()) {
        return ['Total']
      }
      const catg = []
      switch (this.groupingSummary.selected) {
        case 'Per Project':
          catg.push('Project')
          break
        case 'Per project and user':
          catg.push('Project')
          catg.push('User')
          break
        case 'Per user':
          catg.push('User')
          break
      }
      if (this.stateSummary.selected === 'Per state') {
        catg.push('State')
      }
      switch (this.timeSummary.selected) {
        case 'Monthly':
          catg.push('Month')
          break
        case 'Weekly':
          catg.push('Calendar Week')
          break
      }
      if (this.nnodesSummary.selected === 'Per job size') {
        catg.push('Nodes')
      }
      if (this.partitionSummary.selected === 'Per partition') {
        catg.push('Partition')
      }

      return catg
    },

    async copy () {
      if (this.summaryData.length > 0) {
        const catg = this.getCategories()
        let copytext = catg.join(';')
        copytext += ';Core Hours;Number Of Jobs\n'
        this.summaryData.forEach(row => {
          catg.forEach(category => {
            if (row[category] === undefined) {
              copytext += '-;'
            } else {
              copytext += row[category] + ';'
            }
          })
          copytext += row.Core_Hours + ';' + row['Number of Jobs'] + '\n'
        })
        await navigator.clipboard.writeText(copytext)
      }
    },

    reEmitTL (msg) {
      this.$emit('timeline', msg)
    }
  }
}
</script>

<style scoped>
.ovf {
  overflow-x: scroll;
}
table#summary-table .flip-list-move {
  transition: transform 1s;
}
</style>
