<template>
  <b-container fluid>
   <b-row class="lrzcolor" v-if="property">
     <!-- Go back button -->
     <b-col>
       <GoBack/>
     </b-col>

     <!-- property dropdown list -->
     <b-col>
       <b-dropdown id="dd1" split :text="property.name" class="m-md-2 dd-scroll" size="sm">
         <b-dd-item-button
          v-for="(prop, index) in this.properties"
          :key="index"
          @click="updateProperty(prop, false)"> {{ prop.name }}
         </b-dd-item-button>
       </b-dropdown>
     </b-col>

     <!-- Property info -->
     <b-col>
       <b-button id="infoB" class="m-md-2" size="sm"> Property Info </b-button>
       <b-popover placement="bottomright" target="infoB" triggers="click">
         <template #title>{{ property.name }}</template>
         <h6> {{ property.explanation }} </h6>
         <p class="mb-0"> <strong>Units: </strong> {{ property.unit }} </p>
         <strong>Optimization hints: </strong> {{ property.hints }} <br>
         <strong>Measured per {{ property.domain }} </strong>
       </b-popover>
     </b-col>

     <!-- show threshold -->
     <b-col>
       <b-button class="m-md-2" size="sm" @click="updateThreshold()" >Toggle Severity</b-button>
     </b-col>

     <!-- Quantiles dropdown -->
     <b-col>
        <b-dropdown id="series" split text="Choose quantiles" class="m-md-2" size="sm">
          <b-dropdown-form>
            <b-form-checkbox v-model="plottedColumns[0]" @input="updateGraph(0, graph)">Average </b-form-checkbox>
            <b-form-checkbox v-for="(q, idx) in 11" :key="idx" v-model="plottedColumns[idx + 1]" @input="updateGraph(idx + 1, graph)">
              <nobr>{{ 100 - (idx * 10) }}% Quantile</nobr>
            </b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
     </b-col>
     <b-col> <JobInfo/> </b-col>
     <b-col> <ExportButton ref="expButton"/> </b-col>
   </b-row>
   <b-row>
     <div ref="graphdiv">Loading first graph...</div>
   </b-row>
   <b-row class="lrzcolor" v-if="propertyRef">
     <b-col cols="1"/>
     <!-- property dropdown list -->
     <b-col>
       <b-dropdown id="dd2" split :text="propertyRef.name" class="m-md-2 dd-scroll" size="sm">
         <b-dd-item-button
           v-for="(prop, index) in this.properties"
           :key="index"
           @click="updateProperty(prop, true)">{{ prop.name }}
         </b-dd-item-button>
       </b-dropdown>
     </b-col>

     <!-- Property info -->
     <b-col>
       <b-button id="infoRef" :title="propertyRef.name" class="m-md-2" size="sm"> Property Info </b-button>
       <b-popover placement="bottomright" target="infoRef" triggers="click">
         <template #title>{{ propertyRef.name }}</template>
         <h6> {{ propertyRef.explanation }} </h6>
         <p class="mb-0"> <strong>Units: </strong> {{ propertyRef.unit }} </p>
         <strong>Optimization hints: </strong> {{ propertyRef.hints }} <br>
         <strong>Measured per {{ propertyRef.domain }} </strong>
       </b-popover>
     </b-col>
     <b-col> <ExportButton ref="expButtonRef"/> </b-col>
     <!--b-col> <JobScriptButton/> </b-col-->
     <b-col cols="2"/>
   </b-row>
   <b-row>
     <div ref="refgraph">Loading second graph...</div>
   </b-row>
   <b-row>
     <br><br><br> <!-- A bit of padding on the bottom-->
   </b-row>
  </b-container>
</template>

<script>
import Dygraph from 'dygraphs'
import { synchronize } from '@/assets/hack/synchronizer.js'
import errorcommon from '@/common/error.js'

export default {
  name: 'Performance',

  data () {
    const graphCls = ['#FF00FF', // AVG value
      '#0033CC', '#6666CC', '#66FFFF', '#33FF99', '#00CC33', '#006600', '#CCFF66', '#FFFF00', '#FF9900', '#FF3300', '#CC0000']
    const seriesLbl = []
    for (let i = 0; i < 11; ++i) {
      seriesLbl.unshift(`${i * 10}% Quantile`)
    }
    seriesLbl.unshift('Average')
    seriesLbl.unshift('Time')

    return {
      jobId: 0,
      graphColors: graphCls,
      seriesLabels: seriesLbl,
      plottedColumns: [true, true, false, false, false, false, true, false, false, false, false, true],
      jobDataTable: undefined,
      properties: undefined,
      // First graph
      graph: undefined,
      propId: 0,
      property: undefined,
      // Second graph
      graphRef: undefined,
      propRefId: 0,
      propertyRef: undefined,
      // sync graphs
      syngs: undefined,
      thresholdToggle: false
    }
  },

  methods: {
    updateProperty (prop, isRef) {
      const self = this
      try {
        self.syngs.detach()
      } catch (e) {
        errorcommon.handleError(e, self.$router)
      }
      isRef ? self.propRefId = prop.property_type_id : self.propId = prop.property_type_id
      isRef ? self.propertyRef = prop : self.property = prop
      if (isRef) {
        self.graphRef.destroy()
        self.graphRef = self.makeGraph(self.$refs.refgraph, self.propRefId)
        self.$refs.expButtonRef.updatePropId(self.propRefId)
      } else {
        self.graph.destroy()
        self.graph = self.makeGraph(self.$refs.graphdiv, self.propId)
        self.$refs.expButton.updatePropId(self.propId)
      }
      self.syngs = synchronize(self.graph, self.graphRef, {
        selection: true,
        zoom: true,
        range: false
      })
    },

    updateThreshold () {
      const self = this
      try {
        self.syngs.detach()
      } catch (e) {
        errorcommon.handleError(e, self.$router)
      }
      self.thresholdToggle = !self.thresholdToggle
      self.graphRef.destroy()
      self.graphRef = self.makeGraph(self.$refs.refgraph, self.propRefId)
      self.graph.destroy()
      self.graph = self.makeGraph(self.$refs.graphdiv, self.propId)
      self.syngs = synchronize(self.graph, self.graphRef, {
        selection: true,
        zoom: true,
        range: false
      })
    },

    updateGraph (idx, graphObj) {
      graphObj.setVisibility(idx, this.plottedColumns[idx])
    },

    getCSVData (propId) {
      const self = this
      const tdArray = self.jobDataTable.tableRowMap.get(propId)
      let csvdata = ''
      if (tdArray) {
        tdArray.forEach(td => {
          if (td.propQuantiles.length !== 0) {
            csvdata += td.timestamp + ',' + td.propertyAverage + ',' + td.propQuantiles.slice().reverse().join() + '\n'
          }
        })
      }
      return csvdata
    },

    findProperty (propId) {
      const self = this
      const propObj = self.properties.find(prop => {
        return prop.property_type_id === propId
      })
      return propObj
    },

    howManyBytes (propObj) {
      if (propObj) {
        if (propObj.unit.startsWith('KByte')) {
          return 1000
        } else if (propObj.unit.startsWith('Byte')) {
          return 1
        }
      }
      return 0 // no Bytes
    },

    isMHz (propObj) {
      if (propObj && propObj.unit === 'MHz') {
        return true
      }
      return false
    },

    hasPerSec (propObj) {
      if (propObj && propObj.unit.endsWith('/s')) {
        return true
      }
      return false
    },

    makeGraph (graphref, propId) {
      const self = this
      const mywidth = window.innerWidth // Math.round(window.innerWidth * 0.9)
      const myheight = Math.round(window.innerHeight * 0.4)
      const propObj = self.findProperty(propId)
      const numberBytes = self.howManyBytes(propObj)
      const isPerSec = self.hasPerSec(propObj)
      const isMegaHz = self.isMHz(propObj)
      let humanReadable

      if (numberBytes > 0) {
        humanReadable = function (val) {
          const tbytes = val * numberBytes
          const persec = isPerSec ? '/s' : ''
          if (tbytes > 1e9) {
            return `${tbytes / 1e9}GB${persec}`
          } else if (tbytes > 1e6) {
            return `${tbytes / 1e6}MB${persec}`
          } else if (tbytes > 1e3) {
            return `${tbytes / 1e3}KB${persec}`
          }
          return tbytes
        }
      } else if (isMegaHz) {
        humanReadable = function (val) {
          const raw = val * 1e6
          if (raw > 1e9) {
            return `${raw / 1e9}GHz`
          } else if (val > 1e6) {
            return `${(raw / 1e6)}MHz`
          } else if (raw > 1e3) {
            return `${(raw / 1e3)}KHz`
          }
          return raw
        }
      } else {
        humanReadable = function (val) {
          if (val > 1e9) {
            return `${(val / 1e9)}G`
          } else if (val > 1e6) {
            return `${(val / 1e6)}M`
          } else if (val > 1e3) {
            return `${(val / 1e3)}K`
          }
          return val
        }
      }

      const options = {
        colors: self.graphColors,
        labels: self.seriesLabels,
        height: myheight,
        width: mywidth,
        visibility: self.plottedColumns,
        labelsSeparateLines: true,
        legend: 'follow',
        axes: {
          y: {
            axisLabelFormatter: humanReadable,
            valueFormatter: humanReadable,
            axisLabelWidth: 60
          }
        }
      }
      if (self.thresholdToggle) {
        const pinkGoesUp = propObj.formula !== 'FORMULA2'

        const thresholdFunction = function (canvas, area, g) {
          // splitY is a coordinate on the canvas
          const extremes = g.yAxisRanges()
          let splitY = g.toDomYCoord(propObj.threshold)
          let thrOutOfScope = false
          if (propObj.threshold > extremes[1]) {
            splitY = extremes[1]
            thrOutOfScope = true
          }

          // The drawing area doesn't start at (0, 0), it starts at (area.x, area.y).
          // That's why we subtract them from splitY. This gives us the
          // actual distance from the upper-left hand corder of the graph itself.
          const topHeight = splitY - area.y
          const bottomHeight = area.h - topHeight

          // fillRect(x, y, width, height)
          // Top section y = (prop.threshold, Infinity)
          if (!thrOutOfScope) {
            canvas.fillStyle = pinkGoesUp ? 'pink' : 'lightgreen'
            canvas.fillRect(area.x, area.y, area.w, topHeight)
          }

          // Bottom section: y = (-Infinity, prop.threshold)
          canvas.fillStyle = pinkGoesUp ? 'lightgreen' : 'pink'
          canvas.fillRect(area.x, splitY, area.w, bottomHeight)
        }
        options.underlayCallback = thresholdFunction
      }

      const csvdata = self.getCSVData(propId)

      const graphObj = new Dygraph(
        graphref,
        csvdata,
        options
      )
      return graphObj
    }
  },

  async mounted () {
    const self = this
    const jobId = self.jobId = self.$router.currentRoute.params.jobId
    self.propId = self.$router.currentRoute.params.propId

    try {
      if (!self.$store.getters.hasProperties) {
        await self.$store.dispatch('fetchProperties').catch(error => { errorcommon.handleError(error, self.$router) })
        if (!self.$store.getters.hasPropertyTree) { // needed to sort properties
          await self.$store.dispatch('fetchPropertyTree').catch(error => { errorcommon.handleError(error, self.$router) })
        }
      }
      if (!self.$store.getters.getJobPerfData(jobId)) { // performance data
        await self.$store.dispatch('fetchPerformanceDataFromJob', { jobId }).catch(error => { errorcommon.handleError(error, self.$router) })
      }
      if (!self.$store.getters.hasPropertyJobTable(self.jobId)) {
        await self.$store.dispatch('calculatePropertyJobTable', { jobId }).catch(error => { errorcommon.handleError(error, self.$router) })
      }
      const averageSeverities = self.$store.getters.averageSeverity(self.jobId)
      self.properties = this.$store.getters.getActivePropInJob(self.jobId, averageSeverities)

      self.jobDataTable = this.$store.getters.getPropertyJobTable(jobId)

      self.property = self.properties.find(prop => {
        return prop.property_type_id === self.propId
      })

      self.propRefId = self.properties[0].property_type_id
      self.propertyRef = self.properties.find(prop => {
        return prop.property_type_id === self.propRefId
      })
      self.$nextTick(() => {
        try {
          self.$refs.expButton.updatePropId(self.propId)
          self.$refs.expButtonRef.updatePropId(self.propRefId)
          self.graph = self.makeGraph(self.$refs.graphdiv, self.propId)
          self.graphRef = self.makeGraph(self.$refs.refgraph, self.propRefId)
          self.syngs = synchronize(self.graph, self.graphRef, {
            selection: true,
            zoom: true,
            range: false
          })
        } catch (error) { // something is fishy...
          self.$router.go(-1)
        }
      })
    } catch (error) {
      errorcommon.handleError(error, self.$router)
    }
  }
}
</script>

<style scoped>
.lrzcolor {
  background-color: #6eaddf;
}

.dd-scroll /deep/ .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.dygraph-legend {
  background: rgba(108, 117, 125, 0.75) !important;
  border-radius: 10px;
  box-shadow: 4px 4px 4px #888;
}

#graphdiv {
 position: absolute;
}
</style>
