<template>
  <div>
    <b-modal ref="searchFilterModal" title="Job Search Filter">
      <!-- Inputs for job search details -->
      <b-form-group label="Batchdomain" label-cols-sm="4">
        <b-form-radio-group
          v-model="form.data.batchdomain"
          :options="form.input.batchdomain"
          value-field="item"
          text-field="name"
          disabled-field="notEnabled"
        ></b-form-radio-group>
      </b-form-group>

      <b-input-group prepend="Job IDs" class="mb-3">
        <b-form-input
          v-model="form.data.jobId"
          placeholder="SLURM Job Ids comma separated"
        ></b-form-input>
      </b-input-group>

      <b-form-checkbox
        v-model="form.data.accOnly"
       >
       Fetch accounting data only
      </b-form-checkbox>

      <!-- TODO remove debug output
      <div>{{ form.data }}</div> -->

      <!-- Action buttons on the modal footer -->
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" @click="hide">cancel</b-button>
        <b-button variant="info" @click="search">search</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import errorcommon from '@/common/error.js'

export default {
  name: 'search-jobids',

  data () {
    return {
      form: {
        data: {
          batchdomain: '1010',
          jobId: '',
          accOnly: false
        },
        input: {
          batchdomain: [
            { item: '1010', name: 'Phase 1' },
            { item: 'phase2', name: 'Phase 2', notEnabled: true }
          ]
        }
      }
    }
  },

  validations: {
    form: {
      data: {
        jobId: {
          required,
          minLength: minLength(4)
        }
      }
    }
  },

  methods: {
    show () {
      this.$refs.searchFilterModal.show()
    },

    hide () {
      this.$refs.searchFilterModal.hide()
    },

    async search () {
      const self = this
      try {
        this.hide()
        await this.$store.dispatch('fetchProperties').catch(error => { errorcommon.handleError(error, self.$router) })
        await this.$store.dispatch('fetchPropertyTree').catch(error => { errorcommon.handleError(error, self.$router) })
        this.$store.commit('emptyLocalStorage')
        this.$store.commit('emptyAccAndPerfData')

        // IMPORTANT: accounting information has to be fetched first.
        // It stores the job ids in localStorage which are then used
        // by fetchJobs to get the performance data for all stored job ids.
        const jobIds = this.form.data.jobId.split(' ').join('').split(',')
        this.$emit('fetched', 'Acc')
        await this.$store.dispatch('fetchAccounting', { jobIds }).catch(error => { errorcommon.handleError(error, self.$router) })
        this.$emit('fetched', 'Done')
        // all required data is fetched navigate user to job view
        if (this.form.data.accOnly) {
          if (this.$route.name === 'JobsAO') {
            this.$router.go()
          } else {
            this.$router.push({ name: 'JobsAO' }).catch(_ => { })
          }
        } else {
          if (this.$route.name === 'Job') {
            this.$router.go()
          } else {
            this.$router.push({ name: 'Job' }).catch(_ => { })
          }
        }
      } catch (error) {
        errorcommon.handleError(error, self.$router)
      }
    }
  }
}
</script>
