import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import NotFound from '@/views/NotFound.vue'
import Job from '@/views/Job.vue'
import Timeline from '@/views/Timeline.vue'
import Performance from '@/views/Performance.vue'
import JobAccOnly from '@/views/JobAccOnly.vue'
import SessionTimeOut from '@/views/SessionTimeOut.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'jobs',
        name: 'Job',
        component: Job,
        meta: { requiresAuth: true }
      },
      {
        path: 'jobs/:jobId',
        name: 'Timeline',
        component: Timeline,
        meta: { requiresAuth: true }
      },
      {
        path: 'jobs/:jobId/:propId',
        name: 'Performance',
        component: Performance,
        meta: { requiresAuth: true }
      },
      {
        path: 'jobsao',
        name: 'JobsAO',
        component: JobAccOnly,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/sessionto',
    name: 'SessionTimeOut',
    component: SessionTimeOut
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function signedIn () {
  const accessToken = window.localStorage.getItem('accessToken')
  const refreshToken = window.localStorage.getItem('refreshToken')
  return accessToken !== null && refreshToken !== null
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (signedIn()) {
      next()
    } else {
      next({ name: 'Login', query: { redirect: to.fullPath } })
    }
  } else {
    next()
  }
})

export default router
