import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'

import { APIClient } from './client.js'
import SearchJobIds from '@/components/SearchJobIds.vue'
import SearchFilter from '@/components/SearchFilter.vue'
import TimelineEvent from '@/components/TimelineEvent.vue'
import TimelineInfo from '@/components/TimelineInfo.vue'
import JobInfo from '@/components/JobInfo.vue'
import PerfJobInfo from '@/components/PerfJobInfo.vue'
import AccountingTable from '@/components/AccountingTable.vue'
import OnlyAccountingTable from '@/components/OnlyAccountingTable.vue'
import AverageTable from '@/components/AverageTable.vue'
import AvgTableHelp from '@/components/AvgTableHelp.vue'
import SeverityGraphHelp from '@/components/SeverityGraphHelp.vue'
import StatisticQuery from '@/components/StatisticQuery.vue'
import ProjectSelect from '@/components/ProjectSelect.vue'
import GoBack from '@/components/GoBack.vue'
import ExportButton from '@/components/ExportButton.vue'
import JobScriptButton from '@/components/JobScriptButton.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'dygraphs/dist/dygraph.css'

window.APIClient = new APIClient()

Vue.config.productionTip = true
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuelidate)

Vue.component('SearchJobIds', SearchJobIds)
Vue.component('SearchFilter', SearchFilter)
Vue.component('TimelineEvent', TimelineEvent)
Vue.component('TimelineInfo', TimelineInfo)
Vue.component('JobInfo', JobInfo)
Vue.component('PerfJobInfo', PerfJobInfo)
Vue.component('AccountingTable', AccountingTable)
Vue.component('OnlyAccountingTable', OnlyAccountingTable)
Vue.component('AverageTable', AverageTable)
Vue.component('SeverityGraphHelp', SeverityGraphHelp)
Vue.component('StatisticQuery', StatisticQuery)
Vue.component('ProjectSelect', ProjectSelect)
Vue.component('AvgTableHelp', AvgTableHelp)
Vue.component('GoBack', GoBack)
Vue.component('ExportButton', ExportButton)
Vue.component('JobScriptButton', JobScriptButton)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
